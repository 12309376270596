import React from "react";

const Home = () => {
  return (
    <section className="home container" id="home">
      <div className="swiper home-swiper">
        <div className="swiper-wrapper">
          <section className="swiper-slide">
            <div className="home__content grid">
              <div className="home__group">
                <img src="/assets/img/sticker/2.png" alt="" className="home__img" />
                <div className="home__indicator"></div>
                <div className="home__details-img">
                  <h4 className="home__details-title">MoonMoo “The Cosmic Cow”</h4>
                  <span className="home__details-subtitle">The Moo from the Farm to the Stars</span>
                </div>
              </div>
              <div className="home__data">
                <h3 className="home__subtitle">#1 Intergalactic Meme Token</h3>
                <h1 className="home__title">TO THE <br /> MOON <br /> MOO!</h1>
                <p className="home__description">
                  Hi, I'm MoonMoo, the cosmic cow that left the farm to explore the universe. 
                  Join the farmyard revolution and be part of a journey where memes and innovation 
                  take us beyond the stars.
                </p>
                <div className="home__buttons">
                  {/*
                  <a href="#" className="button">Join the Farm</a>
                  <a href="#" className="button--link button--flex">
                    Learn More <i className="bx bx-right-arrow-alt button__icon"></i>
                  </a>
                  */}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </section>
  );
};

export default Home;
