import React from "react";

const Footer = () => {
  return (
    <footer className="footer section">
      <div className="footer__container container grid">
        <div className="footer__content">
          <a href="#" className="footer__logo">
            <img src="/assets/img/logo.png" alt="MoonMoo Logo" className="footer__logo-img" />
            MoonMoo
          </a>
          <p className="footer__description">
            Join the herd and explore the stars!
          </p>
          <div className="footer__social">
            <a href="https://www.reddit.com/r/MoonMooToken/" target="_blank" className="footer__social-link">
              <i className="bx bxl-reddit"></i>
            </a>
            <a href="https://www.instagram.com/" target="_blank" className="footer__social-link">
              <i className="bx bxl-telegram"></i>
            </a>
            <a href="https://twitter.com/" target="_blank" className="footer__social-link">
              <i className="bx bxl-twitter"></i>
            </a>
          </div>
        </div>
      </div>
      <span className="footer__copy">&#169; MoonMoo. All rights reserved. To the moon and beyond!</span>
    </footer>
  );
};

export default Footer;
