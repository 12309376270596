import React from "react";

class Roadmap extends React.Component {
  render() {
    return (
    <section className="section buy-tokens" id="roadmap">
      <div className="buy-tokens__container container grid">
      <div className="roadmap">
        <div className="roadmap-header">
          <h2>MoonMoo Roadmap</h2>
          <p className="text text--standard text--regular">
            Embark on the cosmic journey of MoonMoo as we aim to revolutionize the crypto space with our unique vision.
          </p>
          <p>&nbsp;<br/>&nbsp;</p>
        </div>
        <div className="road-body">
          {/* Phase 1 */}
          <div className="card card--secondary card-red">
            <div className="card__header">
              <h3>Phase 1: Preparing for Launch 🚀</h3>
            </div>
            <ul>
              <li>Build and engage the community on Twitter and Telegram</li>
              <li>Develop smart contracts for $MOO</li>
              <li>Launch marketing campaigns and teasers</li>
              <li>Conduct private pre-sale with vesting</li>
            </ul>
          </div>
          {/* Phase 2 */}
          <div className="card card--white card-yellow">
            <div className="card__header">
              <h3>Phase 2: Entering Orbit 🌌</h3>
            </div>
            <ul>
              <li>Launch public pre-sale</li>
              <li>List $MOO on DEXs like Uniswap and PancakeSwap</li>
              <li>Introduce staking and farming rewards</li>
              <li>Establish strategic partnerships</li>
            </ul>
          </div>
          {/* Phase 3 */}
          <div className="card card--white card-green">
            <div className="card__header">
              <h3>Phase 3: Reaching for the Stars 🌠</h3>
            </div>
            <ul>
              <li>Release exclusive MoonMoo NFTs</li>
              <li>Expand ecosystem with GameFi projects</li>
              <li>Launch MoonMoo DAO for community governance</li>
              <li>Conduct large-scale marketing campaigns</li>
            </ul>
          </div>
          {/* Phase 4 */}
          <div className="card card--white card-blue">
            <div className="card__header">
              <h3>Phase 4: Beyond the Moon 🌍🚀</h3>
            </div>
            <ul>
              <li>Develop MoonMoo metaverse</li>
              <li>Enable real-world use cases for $MOO</li>
              <li>List on top-tier centralized exchanges</li>
              <li>Transition governance to the DAO</li>
            </ul>
          </div>
        </div>
      </div>
      
      </div>
    </section>
    );
  }
}

export default Roadmap;
