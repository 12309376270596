import React from "react";

const About = () => {
  return (
    <section className="section about" id="about">
      <div className="about__container container grid">
        <div className="about__data">
          <h2 className="section__title about__title">About MoonMoo <br /> Token</h2>
          <p className="about__description">
            MoonMoo is the token that brings the farm to the cosmos! Inspired by the dream of a cosmic cow 
            reaching the stars, this token combines innovation, fun, and community to create a truly 
            intergalactic experience. Whether you're here for the memes or the mission, MoonMoo has 
            something special for everyone.
          </p>
          {/* <a href="#" className="button">Discover More</a> */}
        </div>
        <img src="/assets/img/sticker/razzo.png" alt="MoonMoo About" className="about__img" />
      </div>
    </section>
  );
};

export default About;
