import React from "react";
import Swiper from "swiper";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import BuyToken from "./components/BuyToken";
import Footer from "./components/Footer";
import RoadMap from "./components/RoadMap";

const App = () => {
  return (
    <>
      <Header />
      <main className="main">
        <Home />
        <About />
        {/*<BuyToken />*/}
        <RoadMap />
      </main>
      <Footer />
    </>
  );
};

export default App;
