import React, { useState } from "react";

const Header = () => {
  // Stato per tenere traccia della voce di menu attiva
  const [activeLink, setActiveLink] = useState("#home");

  // Funzione per aggiornare il link attivo
  const handleSetActive = (link) => {
    setActiveLink(link);
  };

  return (
    <header className="header" id="header">
      <nav className="nav container">
        <a href="#" className="nav__logo">
          <img src="/assets/img/logo.png" alt="MoonMoo Logo" className="nav__logo-img" />
          MoonMoo
        </a>
        <div className="nav__menu" id="nav-menu">
          <ul className="nav__list">
            <li className="nav__item">
              <a
                href="#home"
                className={`nav__link ${activeLink === "#home" ? "active-link" : ""}`}
                onClick={() => handleSetActive("#home")}
              >
                Home
              </a>
            </li>
            <li className="nav__item">
              <a
                href="#about"
                className={`nav__link ${activeLink === "#about" ? "active-link" : ""}`}
                onClick={() => handleSetActive("#about")}
              >
                About
              </a>
            </li>
            <li className="nav__item">
              <a
                href="#roadmap"
                className={`nav__link ${activeLink === "#roadmap" ? "active-link" : ""}`}
                onClick={() => handleSetActive("#roadmap")}
              >
                Roadmap
              </a>
            </li>
            {/*
            <li className="nav__item">
              <a
                href="#farm"
                className={`nav__link ${activeLink === "#farm" ? "active-link" : ""}`}
                onClick={() => handleSetActive("#farm")}
              >
                Farm
              </a>
            </li>
            <li className="nav__item">
              <a
                href="#new"
                className={`nav__link ${activeLink === "#new" ? "active-link" : ""}`}
                onClick={() => handleSetActive("#new")}
              >
                New
              </a>
            </li>
            */}
            <a href="#buy" className="button button--ghost">Support the $MOO</a>
          </ul>
          <div className="nav__close" id="nav-close">
            <i className="bx bx-x"></i>
          </div>
          <img src="/assets/img/nav-img.png" alt="MoonMoo Decoration" className="nav__img" />
        </div>
        <div className="nav__toggle" id="nav-toggle">
          <i className="bx bx-grid-alt"></i>
        </div>
      </nav>
    </header>
  );
};

export default Header;

